
import { Component, Mixins } from "vue-property-decorator";
import { Mixin } from "@/core/mixins/mixin";
import Article from "@/views/Article/components/Article.vue";

@Component({
  components: { Article },
})
export default class ArticlePage extends Mixins(Mixin) {
  mounted(): void {
    (this.$refs["article"] as Article).refreshData();
  }
}
